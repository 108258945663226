<template>
  <a-card>
    <a-tabs :default-active-key="tabsType" @change="changeTabs"> 
      <a-tab-pane key="1" tab="平台全包价格">
        <servesFree v-if="tabsType == 1"/>
      </a-tab-pane>
      <a-tab-pane key="2" tab="模块介绍设置" force-render>
        <moduleIntroduce v-if="tabsType == 2"></moduleIntroduce>
      </a-tab-pane>
      <a-tab-pane key="3" tab="服务费用管理" force-render>
        <serviceCharge v-if="tabsType == 3"/>
      </a-tab-pane>
    </a-tabs>
  </a-card>

</template>
<script>
  import { $iscode } from "@/utils/app";
  import { ref, reactive, toRefs, inject } from "vue";
  import { message } from "ant-design-vue";
  import {brandAppsIndex} from '@/api/product'
  import servesFree from './components/servesFree';
  import moduleIntroduce from './components/module_introduce'
  import serviceCharge from './components/serviceCharge'

  export default {
    name: "product",
    components: { servesFree,moduleIntroduce,serviceCharge },
    setup(props) {
       const tabsType = ref('1')
      const methods = {
            changeTabs(e){
              tabsType.value = e;
            }
      }
      return {
        ...methods,
        tabsType
      };
    },
  };
</script>